import { getExpConfig } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'

const getRatingsConfig = () => {
  const ratingsSplitData = getExpConfig(SPLIT_FEATURES.RATINGS)
  const isEnabled = ratingsSplitData?.treatment === 'on'
  const config = ratingsSplitData?.config

  let ratingsConfig = { isFeatureEnabled: isEnabled }
  if (config) {
    ratingsConfig = {
      ...ratingsConfig,
      ...JSON.parse(config),
    }
  }
  return ratingsConfig
}

export { getRatingsConfig }
