import {
  fetchCategory,
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
  PAGE_TYPES,
  SPLIT_EVENT,
} from './eventTrackerFunction'
import { isOutOfStock, OUT_OF_STOCK, IN_STOCK } from './getInventoryStatus'
import { objectValues } from './polyfills'

import {
  isCitrusProductTrackingEnabled,
  trackCitrusAdProductClick,
} from './trackCitrus'
import { getRatingsConfig } from '../components/RatingStars/ratingsUtils'
import { isBoughtBefore } from '../utils/Utils'
import { formatCtaLocation, getPageTypeByUrl } from './amplitude'
import { getCtaLocationMeta } from './utils'

const getProductImpressionsTrackingData = ({
  eventType = 'productImpression',
  productObj = {},
  ga4ProductListName,
}) => {
  const {
    countOf,
    productPosition,
    details,
    checkoutAddress,
    productListName,
    bulkOrderThreshold,
    storeBulkOrderThreshold,
    remoteConfig,
  } = productObj
  const { primaryCategory, storeSpecificData, offers, reviews } = details
  const ratingsConfig = getRatingsConfig()
  const categoryList = fetchCategory(primaryCategory)
  const storeSpecific = storeSpecificData?.length ? storeSpecificData[0] : {}
  const offerPrice = (storeSpecific.mrp - storeSpecific.discount).toFixed(2)
  const offersToTrack = offers && offers.slice(0, 2)
  const promoText =
    offersToTrack && offersToTrack.map(offer => offer.description).join('|')
  const itemIsOutOfStock = isOutOfStock({
    value: countOf(details),
    stock: storeSpecific.stock,
    unlimitedStock: storeSpecific.unlimitedStock || false,
    bulkOrderThreshold,
    storeBulkOrderThreshold,
    checkoutAddress,
  })

  let isProductBoughtBefore

  if (eventType == 'productClick') {
    isProductBoughtBefore =
      details?.pastPurchases && isBoughtBefore(details?.pastPurchases)
        ? 'yes'
        : 'no'
  } else {
    isProductBoughtBefore = ''
  }

  const trackingObj = {
    refId: `${checkoutAddress?.clientId}-${details?.clientItemId}`,
    adId: details?.adId?.toString() || '',
    [GEV.ECOMM_NAME]: getNCheck(details, 'name', '').toString(),
    [GEV.ECOMM_ID]: getNCheck(details, 'clientItemId', '').toString(),
    [GEV.ECOMM_PRODUCT_ID_ZOP]: getNCheck(details, 'id', '').toString(),
    [GEV.ECOMM_PRICE]: parseFloat(getNCheck(storeSpecific, 'mrp', ''))
      .toFixed(2)
      .toString(),
    [GEV.ECOMM_SELLING_PRICE]: parseFloat(getNCheck(storeSpecific, 'mrp', ''))
      .toFixed(2)
      .toString(),
    [GEV.ECOMM_BRAND]: getNCheck(details, 'brand.name', '').toString(),
    [GEV.ECOMM_CATEGORY]: objectValues(categoryList).join('/'),
    [GEV.ECOMM_DIMENSION32]: getNCheck(storeSpecific, 'stock', '').toString(),
    [GEV.ECOMM_DIMENSION60]: getNCheck(
      checkoutAddress,
      'clientId',
      ''
    ).toString(),
    [GEV.ECOMM_VARIANT]: getNCheck(
      details,
      'metaData.DisplayUnit',
      ''
    ).toUpperCase(),
    [GEV.ECOMM_POSITION]: productPosition,
    [GEV.ECOMM_DIMENSION61]: itemIsOutOfStock ? OUT_OF_STOCK : IN_STOCK,
    [GEV.ECOMM_IS_PAST_PURCHASE]: isProductBoughtBefore,
    [GEV.ECOMM_OFFER_PRICE]: offerPrice ? offerPrice.toString() : '',
    [GEV.ECOMM_PROMO_TEXT]: promoText,
    [GEV.DIMENSION84]: window.location?.pathname,
    [GEV.DIMENSION97]:
      isCitrusProductTrackingEnabled(remoteConfig) && details?.adId
        ? `ct_${details?.adId}`
        : '',
    [GEV.DIMENSION106]: details?.track || '',
  }
  if (eventType === 'productImpression') {
    trackingObj[GEV.ECOMM_LIST] = productListName
  }
  if (ratingsConfig?.isFeatureEnabled && ratingsConfig?.isPlpStarsEnabled) {
    trackingObj[GEV.DIMENSION101] = reviews?.statistics?.averageDisplay || ''
    trackingObj[GEV.DIMENSION102] = reviews?.statistics?.total || ''
  }

  if (ga4ProductListName) {
    trackingObj[GEV.ECOMM_LIST] = ga4ProductListName
  }

  return trackingObj
}

const getProductClickTrackingData = ({
  productObj = {},
  extendedProperties = {},
}) => {
  const {
    productListName,
    searchTerm,
    remoteConfig,
    details,
    metaData,
    ga4ProductListName,
  } = productObj

  if (details?.adId) {
    const adId = getNCheck(details, 'adId', '')
    trackCitrusAdProductClick([adId], remoteConfig)
  }

  const productData = getProductImpressionsTrackingData({
    eventType: 'productClick',
    productObj: productObj,
  })

  const isProductBoughtBefore =
    details?.pastPurchases && isBoughtBefore(details?.pastPurchases)
      ? 'yes'
      : 'no'

  const trackingObj = {
    [GEV.ALGOLIA_EXP]:
      metaData?.abTestId && metaData?.abTestVariantId
        ? `algolia-${metaData?.abTestId}-${metaData?.abTestVariantId}`
        : undefined,
    [GEV.EVENT]: 'productClick',
    [GEV.EVENT_ACTION]: 'productclick',
    [GEV.ASSET_TYPE]: 'fpon',
    [GEV.EVENT_CAT]: 'ecommerce-engagement',
    [GEV.SNIPPET_VERSION]: 'ga4',
    [GEV.SCREEN_NAME_GA4]: formatCtaLocation(
      {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      },
      'grocery-online'
    ),
    [GEV.ECOMMERCE]: {
      click: {
        actionField: { list: productListName || PAGE_TYPES.OTHERS },
        products: [
          {
            ...productData,
            ...extendedProperties,
            ...(ga4ProductListName
              ? { [GEV.ECOMM_LIST]: ga4ProductListName }
              : {}),
          },
        ],
      },
    },
    [GEV.SEARCH_TERM]: searchTerm || '',
    [GEV.KEYWORD_SELECTED]: searchTerm || '',
    [GEV.AMPLITUDE_IS_PAST_PURCHASE]: isProductBoughtBefore,
    [GEV.DIMENSION106]: details?.track || '',
  }
  return trackingObj
}

//This method creates payload for product impressions & click tracking
//to be sent to split for statistical analysis
const getSplitProductTrackingData = ({
  eventType = 'splitProductImpression',
  productObj = {},
}) => {
  const {
    productPosition,
    details,
    checkoutAddress,
    productListName,
    remoteConfig,
  } = productObj

  const splitTrackingObj = {
    refId: `${checkoutAddress?.clientId}-${details?.clientItemId}`,
    event_type: SPLIT_EVENT,
    [GEV.EVENT]: eventType || SPLIT_EVENT,
    [GEV.ECOMM_NAME]: getNCheck(details, 'name', '').toString(),
    [GEV.ECOMM_ID]: getNCheck(details, 'clientItemId', '').toString(),
    [GEV.ECOMM_PRODUCT_ID_ZOP]: getNCheck(details, 'id', '').toString(),
    [GEV.ECOMM_LIST]: productListName,
    store_id: getNCheck(checkoutAddress, 'clientId', '').toString(),
    [GEV.ECOMM_POSITION]: productPosition,
    page_slug: window.location?.pathname,
    citrus_adid:
      isCitrusProductTrackingEnabled(remoteConfig) && details?.adId
        ? `ct_${details?.adId}`
        : '',
  }

  return splitTrackingObj
}

export {
  getProductImpressionsTrackingData,
  getProductClickTrackingData,
  getSplitProductTrackingData,
}
