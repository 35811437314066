const OUT_OF_STOCK = 'Out of stock'
const IN_STOCK = 'In Stock'

const isOutOfStock = ({
  value,
  stock,
  unlimitedStock,
  bulkOrderThreshold,
  storeBulkOrderThreshold,
}) => {
  if (unlimitedStock) {
    return false
  }

  let bulkQty = Math.min(bulkOrderThreshold || 0, storeBulkOrderThreshold || 0)

  if (bulkOrderThreshold && !storeBulkOrderThreshold) {
    bulkQty = bulkOrderThreshold
  } else if (storeBulkOrderThreshold && !bulkOrderThreshold) {
    bulkQty = storeBulkOrderThreshold
  } else if (bulkOrderThreshold && storeBulkOrderThreshold) {
    bulkQty = Math.min(bulkOrderThreshold, storeBulkOrderThreshold)
  }

  if (bulkQty > 0 && bulkQty <= value) {
    return false
  }

  return !stock
}

export { isOutOfStock, OUT_OF_STOCK, IN_STOCK }
